import { isIntuit } from "Intuit/isIntuit";

import { CompanyApplication } from "../Utils/types";

export const baseURL = "/employer-application";

// Transform a route into an array of routes, to handle the various ways we use the EmployeeApplication
// Convert this to a nested route in v6
// Multiple path support for <Route /> will go away in v6, hooks will be used instead.
export const nestedPrefixRoutes = (url) => [url, `/company/:companyIDToken${url}`, `/:partner/router${url}`, `/:partner/router/company/:companyIDToken${url}`];

// APIs
export const api = {
  // Company Profile State for Store
  // Used in ER and ER Renewal apps' Sagas to populate Redux Store.
  companyProfile: "/company_profile",

  // ApplicationStatus
  checkApplicationStatus: `${baseURL}/ajax-check-application-status`,

  // Employee CRUD.
  addEmployee: `${baseURL}/ajax-add-employee`,
  deleteEmployee: `${baseURL}/ajax-remove-employee`,
  updateEmployee: `${baseURL}/ajax-update-employee`,
  updateEmployeeContributions: `${baseURL}/ajax-update-employee-contributions`,
  setCoverageExceptions: `${baseURL}/ajax-set-exceptions`,
  setAddEmployeesStepComplete: `${baseURL}/ajax-set-add-employees-step-complete`,

  // Send application to employees.
  sendEmployeeApplications: `${baseURL}/review-and-send-application-links`,

  // Application Menu State for Store (Documents Page)
  documentsMenuState: `${baseURL}/ajax-documents-menu-state`,

  // Upload documents POST url. This is the same url to get.
  // Only used in Zero Ruby.
  documentsUpload: `${baseURL}/ajax-upload-documents`,
  wageDocumentsUpload: `${baseURL}/ajax-upload-wage-documents`,
  employerApplicationDocumentValidate: `${baseURL}/ajax-validate-employer-application-document`,
  // Save payment information.
  savePayment: `${baseURL}/ajax-save-payment`,

  // Employee type overrides
  enableOverrideEmployeeType: `${baseURL}/enable-override-employee-type`,
  disableOverrideEmployeeType: `${baseURL}/disable-override-employee-type`,
};

// Routes
// - Company Info
export const companyInfoURL = `${baseURL}/company-info`;
export const coverageOptionsURL = `${baseURL}/coverage-options`;
export const companyInformationURL = `${baseURL}/company-information`;
export const createAccountURL = `${baseURL}/create-account`;
// - AddEmployees App
export const addEmployeesURL = `${baseURL}/add-employees`;
export const selectContributionTiersURL = `${baseURL}/select-contribution-tiers`;
export const reviewEmployeeEmailsURL = `${baseURL}/send-employee-emails`;
// - Documents App
export const documentsUploadPageURL = `${baseURL}/documents/upload`;
// - Payment App
export const paymentURL = `${baseURL}/payment`;
// - Done App
export const applicationReadyURL = `${baseURL}/done`;

export const connectIntegrationsURL = `${baseURL}/connect-integrations`;

// Single Page Employer Application specific urls
export const overviewURL = `${baseURL}/overview`;
export const finalizeQuoteURL = `${baseURL}/finalize-quote`;

/** Page numbers (for analytics) */
export const mapERPathsToStepIndex = {
  "/small-group/select-plan": 1,
  [coverageOptionsURL]: 2,
  [companyInformationURL]: 2,
  [createAccountURL]: 2,
  [addEmployeesURL]: 3,
  [selectContributionTiersURL]: 3,
  [reviewEmployeeEmailsURL]: 3,
  [documentsUploadPageURL]: 4,
  [paymentURL]: 4,
  [applicationReadyURL]: 4,
  /** (optional) Partner Integrations e.g. Finch */
  [connectIntegrationsURL]: 4,
} as const;

/** Possible step numbers for the ER app
 * @example STEP_NUMBER of 4
 * */
export type ERAppStepIndex = (typeof mapERPathsToStepIndex)[keyof typeof mapERPathsToStepIndex];

export const isAnEmployerApplicationPage = () => window.location.pathname.indexOf(baseURL) !== -1;

/**
 * Dynamically determines what the next page is for the employer app
 * @note Only partially implemented for step 4 - full implementation requires a non-trivial refactor
 * */
export const nextEmployerApplicationURL = (currentPath, _companyApplication: CompanyApplication) => {
  switch (currentPath) {
    case paymentURL:
      if (isIntuit()) {
        return connectIntegrationsURL;
      }
      return applicationReadyURL;
    case connectIntegrationsURL:
      return applicationReadyURL;
    default:
      return overviewURL;
  }
};

export const getStepFromPath = (path: string): ERAppStepIndex => {
  return Object.entries(mapERPathsToStepIndex).find(([key]) => path.includes(key))?.[1];
};
