import isBambee from "Bambee/isBambee";
import { bambeeBaseUrl } from "Bambee/urls";
import isCheckhq from "Checkhq/isCheckhq";
import { checkhqBaseUrl } from "Checkhq/urls";
import isEveryio from "Everyio/isEveryio";
import isGusto from "Gusto/isGusto";
import isIntuit from "Intuit/isIntuit";
import { intuitBaseUrl } from "Intuit/urls";
import isPrimepay from "Primepay/isPrimepay";
import { primepayBaseUrl } from "Primepay/urls";
import isSquare from "Square/isSquare";
import { squareBaseUrl } from "Square/urls";

// Very similar to baseUrl from client/src/ZeroRuby/urls.js
// Should not be used for backend requests (like building urls for ajax requests). If the
// backend needs partner info it can access through company. Normally, this also shouldn't
// be used for in-app navigation. The app-level router should take care of that correctly.
export const routerUrl = () =>
  isIntuit()
    ? intuitBaseUrl
    : isSquare()
      ? squareBaseUrl
      : isPrimepay()
        ? primepayBaseUrl
        : isBambee()
          ? bambeeBaseUrl
          : isCheckhq()
            ? checkhqBaseUrl
            : isEveryio()
              ? "/everyio/router"
              : isGusto()
                ? "/gusto/router"
                : window.SIPartnerConfiguration?.routerPrefix
                  ? `/${window.SIPartnerConfiguration.partner}/router`
                  : "";

export const routerUrlOrFalse = () => (routerUrl() == "" ? false : routerUrl());

export const routerUrlHelper = (url: string) => routerUrl() + url;
