import { companyTokenString } from "Utils/companyToken";
import { routerUrl } from "Utils/routerUrl";

export const baseURL = "/register";
export const basicInformationURL = "/basic-information";
export const signURL = "/sign";
export const successURL = "/success";
export const contributionURL = "/policy";

export const landingPageURL = "/landing-page";
export const companyInformationURL = "/company-information";
export const currentPlanURL = "/current-plan";
export const contributionsURL = "/contributions";
export const confirmationURL = "/confirmation";
export const doneURL = "/done";

export const isABorPage = () => window.location.pathname.indexOf("/register") !== -1;

export const borUrl = (idToken: string | null | undefined): string => `${routerUrl()}${companyTokenString(idToken)}${baseURL}`;
