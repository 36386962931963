import React from "react";

import classnames from "classnames";
import isIntuit from "Intuit/isIntuit";
import { isTrowserRollout } from "Intuit/TrowserUtils";
import { getDisplayName } from "Utils/react";

import { useThemeContext } from "Theme/Context";

/**
 * @deprecated Please use tailwind spacing classes
 * @see https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
 * */
export type Size = 0 | 1 | 2 | 3 | 4 | 5 | 6 | "auto";
/**
 * Internal type for negative values we can support if absolutely needed
 * @deprecated Please use tailwind spacing classes, and avoid negative values
 * Do not re-export, this is not meant for general use
 * */
type NegativeSize = -1 | -2 | -3 | -4 | -5 | -6;

/** @deprecated Internal - do not export */
type Spacing = Size | NegativeSize;

export const sizes: Spacing[] = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, "auto"];

// Props relating to margin and padding
export interface FullSpacingProps {
  m: Spacing;
  mx: Spacing;
  my: Spacing;
  smm: Spacing;
  smmx: Spacing;
  smmy: Spacing;
  mdm: Spacing;
  mdmx: Spacing;
  mdmy: Spacing;
  lgm: Spacing;
  lgmx: Spacing;
  lgmy: Spacing;
  p: Spacing;
  px: Spacing;
  py: Spacing;
  smp: Spacing;
  smpx: Spacing;
  smpy: Spacing;
  mdp: Spacing;
  mdpx: Spacing;
  mdpy: Spacing;
  lgp: Spacing;
  lgpx: Spacing;
  lgpy: Spacing;
  mt: Spacing;
  mb: Spacing;
  ml: Spacing;
  mr: Spacing;
  smmt: Spacing;
  smmb: Spacing;
  smml: Spacing;
  smmr: Spacing;
  mdmt: Spacing;
  mdmb: Spacing;
  mdml: Spacing;
  mdmr: Spacing;
  lgmt: Spacing;
  lgmb: Spacing;
  lgml: Spacing;
  lgmr: Spacing;
  pt: Spacing;
  pb: Spacing;
  pl: Spacing;
  pr: Spacing;
  smpt: Spacing;
  smpb: Spacing;
  smpl: Spacing;
  smpr: Spacing;
  mdpt: Spacing;
  mdpb: Spacing;
  mdpl: Spacing;
  mdpr: Spacing;
  lgpt: Spacing;
  lgpb: Spacing;
  lgpl: Spacing;
  lgpr: Spacing;
}

/**
 * Mapping of styleProp sizes to Tailwind spacing classes
 * styleProp Sizes: exponential scale through 6
 * Tailwind spacing: linear scale, 4px each
 * */
export const mapBootstrap3SizeToTailwindSpacing: Record<Size, string> = {
  0: "0", // 0px
  1: "0.5", // 2px
  2: "1", // 4px
  3: "2", // 8px
  4: "4", // 16px
  5: "6", // 24px
  6: "8", // 32px
  auto: "auto",
};

// Mapping of styleProp sizes to Tailwind spacing classes for Intuit sizes
export const mapIntuitSizeToTailwindSpacing: Record<Size, string> = {
  0: "0", // 0px
  1: "1", // 4px
  2: "2", // 8px
  3: "4", // 16px
  4: "5", // 20px
  5: "10", // 40px
  6: "15", // 60px
  auto: "auto",
};

// it is useful to have these separate rather than programattic
// and also serves as context for tailwind JIT compiler

export const spacingProps = [
  "m",
  "mx",
  "my",
  "mt",
  "mb",
  "ml",
  "mr",
  "p",
  "px",
  "py",
  "pt",
  "pb",
  "pl",
  "pr",
  "smm",
  "smmx",
  "smmy",
  "mdm",
  "mdmx",
  "mdmy",
  "lgm",
  "lgmx",
  "lgmy",
  "smp",
  "smpx",
  "smpy",
  "mdp",
  "mdpx",
  "mdpy",
  "lgp",
  "lgpx",
  "lgpy",
  "smmt",
  "smmb",
  "smml",
  "smmr",
  "mdmt",
  "mdmb",
  "mdml",
  "mdmr",
  "lgmt",
  "lgmb",
  "lgml",
  "lgmr",
  "smpt",
  "smpb",
  "smpl",
  "smpr",
  "mdpt",
  "mdpb",
  "mdpl",
  "mdpr",
  "lgpt",
  "lgpb",
  "lgpl",
  "lgpr",
];

export interface SpacingProps extends Partial<FullSpacingProps> {}

export interface StyleProps extends SpacingProps {}

export interface OutputProps<T> extends React.HTMLAttributes<T>, StyleProps {}

// This helper method standardizes commonly available Bootstrap styles on
// arbitrary React components.
const styleProps = <T extends {}>(_props: OutputProps<T>): React.HTMLAttributes<T> => {
  const props: any = _props;
  const result = { ...props };
  const classNames: string[] = [];

  if ("className" in result) {
    classNames.push(result.className);
  }

  const isIntuitVariant = isIntuit() && isTrowserRollout();

  spacingProps.forEach((prop) => {
    if (prop in props) {
      const value = props[prop];
      if (value !== undefined) {
        let className = "";

        // Handle responsive prefixes
        let prefix = "";
        let baseProp = prop;

        if (prop.startsWith("smm") || prop.startsWith("smp")) {
          prefix = "sm:";
          baseProp = prop.replace(/^sm(m|p)/, "$1");
        } else if (prop.startsWith("mdm") || prop.startsWith("mdp")) {
          prefix = "md:";
          baseProp = prop.replace(/^md(m|p)/, "$1");
        } else if (prop.startsWith("lgm") || prop.startsWith("lgp")) {
          prefix = "lg:";
          baseProp = prop.replace(/^lg(m|p)/, "$1");
        }

        // Handle 'auto' separately
        if (typeof value === "string" && value === "auto") {
          className = `${prefix}${baseProp}-auto`;
        } else {
          const isNegative = typeof value === "number" && value < 0;
          const absoluteValue = isNegative ? -value : value;
          const mappedValue = isIntuitVariant ? mapIntuitSizeToTailwindSpacing[absoluteValue] : mapBootstrap3SizeToTailwindSpacing[absoluteValue];

          if (mappedValue !== undefined) {
            const negativePrefix = isNegative ? "-" : "";
            className = `${prefix}${negativePrefix}${baseProp}-${mappedValue}`;
          }
        }

        if (isIntuitVariant && className) {
          className += "--intuit";
        }

        if (className) {
          classNames.push(className);
        }
      }
      delete result[prop];
    }
  });

  result.className = classnames(classNames);
  return result;
};

// This decorator will apply the styleProps and pass the remainder on to the
// wrapped component.
export const withStyleProps = <T extends React.ElementType>(
  Component: T,
  defaultProps?: React.ComponentProps<T> & StyleProps,
): React.FunctionComponent<OutputProps<React.ComponentProps<T>>> => {
  const WrappedComponent: React.FunctionComponent<React.ComponentProps<T> & StyleProps> = (props) => {
    const { cobalt, intuit } = useThemeContext();

    let themeProps = {};
    if (intuit && isIntuit()) {
      themeProps = { ...props };
    } else if (cobalt) {
      themeProps = { ...props };
    }

    const allProps = {
      ...(defaultProps ?? {}),
      ...themeProps,
      ...props,
    };

    return <Component {...styleProps(allProps)} />;
  };
  WrappedComponent.displayName = `StyleProps(${getDisplayName(Component)})`;
  return WrappedComponent;
};

export default styleProps;
