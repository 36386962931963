// Environments:
// - production   : Use Square's production environment
// - staging      : Use Square's staging environment
// - scraping     : Use Square's staging environment
// - test         : Use Square's staging environment
// - development  : Use Square's staging environment

if (!process.env.SQUARE_ROOT_URL) {
  throw new Error("SQUARE_ROOT_URL is missing in .env file");
}
const deeplinkRoot = process.env.SQUARE_ROOT_URL;

export const employerDeeplink = (path: string) => `${deeplinkRoot}payroll/employer/simplyinsured?deeplink=${escape(path)}`;
export const employeeDeeplink = (path: string) => `${deeplinkRoot}payroll/employee/simplyinsured/login?deeplink=${escape(path)}`;
export const addEmployeeLink = `${deeplinkRoot}payroll/employer/team`;
export const squareBaseUrl = "/square/router";
export const squareTaxFormLink = `${deeplinkRoot}payroll/employer/tax-forms`;
export const squareHistoryLink = `${deeplinkRoot}payroll/employer/history`;
export const squareBenefitsOnlyTeamMemberLink = `${deeplinkRoot}payroll/employer/employees/benefits-only`;
