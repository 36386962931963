/**
 * Just a tiny helper to create bem classnames.
 * @example const bem = makeBem("DocumentPicker");
 * bem("container"); // "DocumentPicker__container"
 * bem("container", "active"); // "DocumentPicker__container--active"
 * bem(null, "active"); // "DocumentPicker--active"
 */
export default (block: string) => (element?: string | null | undefined, modifier?: string | null | undefined) => {
  const ele = element ? `__${element}` : "";
  const mod = modifier ? `--${modifier}` : "";
  return [block, ele, mod].join("");
};
