import { generatePath } from "react-router-dom";
import { companyTokenString } from "Utils/companyToken";
import { routerUrlOrFalse } from "Utils/routerUrl";

import { appendAuthParams } from "Common/TokenAuth/Utils";

const partnerURLSegment = () => (routerUrlOrFalse() ? routerUrlOrFalse() : window.location.pathname.slice(0, 5) === "/zero" ? "/zero" : "");

// Build a function that returns URLs. All parameters for our routing logic are optionally overridable.
// Also fills in dynamic segments if provided in params
// React Aware routing needs to skip partner and company token prefixes, because they are handled by the nested route configuration.
// Absolute routing needs to keep partner/company token prefixes.
const URLBuilder =
  (name) =>
  ({
    relative = true, // Relative if React Aware, Absolute if not
    /** Can force renewal, partner, idToken, otherwise inherited from current URL */
    renewal = undefined,
    partner = undefined,
    idToken = undefined,
    authSearchParam = true,
    params = {}, // Used to fill in dynamic segments, e.g. /account/:idToken
    searchParams = {},
  } = {}) => {
    const partnerSegment = partner || partnerURLSegment();
    const baseSegment = flowName(renewal);
    const prefixSegments = relative ? "" : `${partnerSegment}${companyTokenString(idToken)}`;
    let url = generatePath(`${prefixSegments}${baseSegment}${name}`, params);
    if (Object.keys(searchParams).length > 0) {
      const queryString = new URLSearchParams(searchParams);
      url = `${url}?${queryString}`;
    }
    if (authSearchParam) {
      url = appendAuthParams(url);
    }
    return url;
  };

export const baseName = "/small-group";
export const renewalBaseName = "/employer-renewal";

export const redirectName = "/small-group/employer-dashboard";

export const isAQuotePage = () => window.location.pathname.indexOf(baseName) !== -1;

export const isRenewal = (): boolean => !!location.pathname.match(/employer-renewal/);
export const flowName = (forceRenewal: boolean | null | undefined): string => {
  const renewalFlow = forceRenewal === undefined ? isRenewal() : forceRenewal;
  return renewalFlow ? renewalBaseName : baseName;
};

export const quotePageName = (renewalFlag: boolean | null | undefined) => `${flowName(renewalFlag)}/select-plan`;
export const shoppingCartName = (renewalFlag: boolean | null | undefined) =>
  renewalFlag || isRenewal() ? "/employer-renewal/coverage-options" : "/employer-application/coverage-options";
export const downloadDecisionPacketName = (renewalFlag: boolean | null | undefined) => `${flowName(renewalFlag)}/download-decision-packet`;
export const quotePageCartName = (renewalFlag: boolean | null | undefined) => `${flowName(renewalFlag)}/select-plan/cart`;
export const planDetailsURL = URLBuilder("/select-plan/plan-details/:planID");
export const planPackageURL = URLBuilder("/select-plan/plan-package/:planID");

export const quotePageURL = (idToken: string | null | undefined, renewalFlag: boolean | null | undefined): string =>
  `${partnerURLSegment()}${companyTokenString(idToken)}${quotePageName(renewalFlag)}`;

export const comparePageURL = URLBuilder("/compare-plans");

export const shoppingCartURL = (idToken: string | null | undefined, renewalFlag: boolean | null | undefined): string =>
  `${partnerURLSegment()}${companyTokenString(idToken)}${shoppingCartName(renewalFlag)}`;

export const quotePageCartUrl = (idToken: string | null | undefined): string => `${partnerURLSegment()}${companyTokenString(idToken)}${quotePageCartName()}`;

export const priceBreakdownURL = URLBuilder("/select-plan/price-breakdown/:planID");

export const comaprePlansPriceBreakdownURL = URLBuilder("/compare-plans/price-breakdown/:planID");

export const enterCompanyDetailsURL = URLBuilder("/enter-company-details");
export const changeZIPCodeURL = URLBuilder("/change-zip-code");
export const downloadDecisionPacketURL = URLBuilder("/download-decision-packet");
