import React, { createContext, useContext } from "react";

import isIntuit from "Intuit/isIntuit";

export type Theme = {
  classic: boolean;
  intuit: boolean;
  cobalt: boolean;
  /**
   * @deprecated no-op: Tailwind is now enabled everywhere
   * @see https://github.com/SimplyInsured/site/pull/7977
   * */
  tailwind: boolean;
  children: React.ReactNode;
};

const initialState = {
  classic: true,
  intuit: true,
  cobalt: false,
};

const ThemeContext = createContext(initialState);

export const ThemeProvider = ({ children, ...initialTheme }: Theme) => {
  // Set intuit dynamically based on user location
  // Note: Intuit users only interact with /intuit pages
  // so that function always returns the same value and only needs to be called once
  const theme = {
    ...initialState,
    intuit: isIntuit(),
    ...initialTheme,
  };

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeContext;
