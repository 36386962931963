export const AUTH_TOKEN_NAMES = {
  ACCESS_TOKEN: "access-token",
  TOKEN_TYPE: "token-type",
  CLIENT: "client",
  EXPIRY: "expiry",
  UID: "uid",
};

const fixNull = (value) => (value === "null" || value === "undefined" ? null : value);

export const getAuthToken = () => {
  const token = {};

  try {
    token.accessToken = fixNull(window.localStorage.getItem(AUTH_TOKEN_NAMES.ACCESS_TOKEN));
    token.client = fixNull(window.localStorage.getItem(AUTH_TOKEN_NAMES.CLIENT));
    token.expiry = fixNull(window.localStorage.getItem(AUTH_TOKEN_NAMES.EXPIRY));
    token.uid = fixNull(window.localStorage.getItem(AUTH_TOKEN_NAMES.UID));
    token.tokenType = fixNull(window.localStorage.getItem(AUTH_TOKEN_NAMES.TOKEN_TYPE));
  } catch (e) {
    /* no-op */
  }

  if (!isValidAuthToken(token) && window.authToken) {
    // we probably don't have access to localStorage.  Fallback to window.authToken
    token.accessToken = window.authToken.accessToken;
    token.client = window.authToken.client;
    token.expiry = window.authToken.expiry;
    token.uid = window.authToken.uid;
    token.tokenType = window.authToken.tokenType;
  }

  return token;
};
export const setAuthToken = (token) => {
  try {
    if (fixNull(token.accessToken)) localStorage[AUTH_TOKEN_NAMES.ACCESS_TOKEN] = fixNull(token.accessToken);
    if (fixNull(token.client)) localStorage[AUTH_TOKEN_NAMES.CLIENT] = fixNull(token.client);
    if (fixNull(token.expiry)) localStorage[AUTH_TOKEN_NAMES.EXPIRY] = fixNull(token.expiry);
    if (fixNull(token.uid)) localStorage[AUTH_TOKEN_NAMES.UID] = fixNull(token.uid);
    if (fixNull(token.tokenType)) localStorage[AUTH_TOKEN_NAMES.TOKEN_TYPE] = fixNull(token.tokenType);
  } catch (e) {
    // we probably don't have access to localStorage.  Fallback to window.authToken
    if (fixNull(token.accessToken)) window.authToken.accessToken = fixNull(token.accessToken);
    if (fixNull(token.client)) window.authToken.client = fixNull(token.client);
    if (fixNull(token.expiry)) window.authToken.expiry = fixNull(token.expiry);
    if (fixNull(token.uid)) window.authToken.uid = fixNull(token.uid);
    if (fixNull(token.tokenType)) window.authToken.tokenType = fixNull(token.tokenType);
  }
};
export const createAuthToken = (accessToken, client, expiry, uid, tokenType = "Bearer") => {
  const token = {};
  token.accessToken = fixNull(accessToken);
  token.client = fixNull(client);
  token.expiry = fixNull(expiry);
  token.uid = fixNull(uid);
  token.tokenType = fixNull(tokenType);
  return token;
};
export const appendAuthParams = (url) => {
  const token = getAuthToken();
  const symbol = url.includes("?") ? "&" : "?";

  if (isValidAuthToken(token)) {
    return `${url}${symbol}${AUTH_TOKEN_NAMES.ACCESS_TOKEN}=${token.accessToken}&${AUTH_TOKEN_NAMES.CLIENT}=${token.client}&${AUTH_TOKEN_NAMES.UID}=${token.uid}`;
  }
  return url;
};
export const isValidAuthToken = (token) => token && token.uid && token.accessToken && token.client;
