import { isABorPage } from "BrokerOfRecord/urls";
import { isAnEmployeeApplicationPage, isRenewal as isAnEmployeeRenewalPage } from "EmployeeApplication/urls";
import { isAnEmployerApplicationPage } from "EmployerApplication/urls";
import isIntuit from "Intuit/isIntuit";
import { isAnIntuitError } from "Intuit/urls";
import { isAQuotePage, isRenewal as isAnEmployerRenewalPage } from "QuotePage/urls";

// If we are inside an iFrame we assume we are in the Intuit Trowser.
export const isIntuitTrowser = () => isIntuit() && (window.top !== window || typeof getTrowserControls() !== "undefined" || isIntuitTrowserMock());

// This is a function to allow us to test the Trowser header in development and staging.
const isIntuitTrowserMock = () => isTrowserRollout() && ["development", "staging"].includes(process.env.ENV_NAME);

export default isIntuitTrowser;

export const getTrowserControls = () => {
  if (window.qboXDM) {
    return window.qboXDM;
  }
};

// Temporary helper function to know which urls have been
// rolled out to support Trowser.
// Once all pages have been rolled out we can remove this
// function.
export const isTrowserRollout = () =>
  isABorPage() ||
  isAQuotePage() ||
  isAnEmployerRenewalPage() ||
  isAnEmployerApplicationPage() ||
  isAnEmployeeApplicationPage() ||
  isAnEmployeeRenewalPage() ||
  isAnIntuitError();
